<template>
    <div class="survey-container has-text-primary">
        <div class="survey-inner-container">
            <div>
                <div class="survey-step">Étape 1/7</div>
                <SurveyHeader>
                    <template #title>
                        <span class="survey-title">Dites-nous en plus sur votre logement.</span>
                    </template>
                </SurveyHeader>

                <!-- type field -->
                <SquareList :items="types" :selected="[type.value]" @click-item="updateType"/>

                <div class="text-error mb-4" v-if="type.error">{{ type.error }}</div>


                <!-- floor field -->
                <div v-if="type.value && ['office', 'apartment'].includes(type.value)">
                    <div class="survey-question">
                        À quel étage est votre {{ mapTypeText[type.value] }}&nbsp;?
                    </div>

                    <RadioButton
                        v-for="o, index in floorOptions"
                        :key="`floor${index}`"
                        :checked="o.checked"
                        @click="changeRadioButton(index, floor, floorOptions)"
                        :label="o.label"
                    />

                    <div v-if="floor.error" class="text-error mt-2">
                        {{ floor.error }}
                    </div>
                </div>


                <!-- constructionPeriod field -->
                <div class="survey-question">
                    Quand votre logement a-t-il été construit&nbsp;?
                </div>

                <RadioButton
                    v-for="o, index in constructionPeriodOptions"
                    :key="`constructionPeriod${index}`"
                    :checked="o.checked"
                    @click="changeRadioButton(index, constructionPeriod, constructionPeriodOptions)"
                    :label="o.label"
                />

                <div v-if="constructionPeriod.error" class="text-error mt-2">
                    {{ constructionPeriod.error }}
                </div>


                <!-- heatedSurface field -->
                <div class="survey-question">
                    Quelle est la surface habitable chauffée&nbsp;?
                    <div class="mt-1 font-size-14 line-height-1-1">Nous vous recommandons d’exclure les garages et dépendances non chauffées.</div>
                </div>

                <div class="control has-icons-right mb-2">
                    <input class="input input-border has-text-primary" :class="{ 'dangerous': heatedSurface.error }" style="padding-left: 1em;"
                        v-model="heatedSurface.value" type="number" placeholder="" min="0">
                    <span class="icon is-right pt-1 custom-input-unit">
                        m²
                    </span>
                </div>

                <div v-if="heatedSurface.error" class="text-error mb-4">{{ heatedSurface.error }}</div>


                <!-- carpentry field -->
                <div v-if="type.value && type.value != 'office' && constructionPeriod.value && buildingAdditionalQuestionsConditions.includes(constructionPeriod.value.split('__')[0])">
                    <div class="survey-question">
                        Les menuiseries actuelles de votre logement sont&nbsp;:
                    </div>

                    <RadioButton
                        v-for="o, index in carpentryOptions"
                        :key="`carpentry${index}`"
                        :checked="o.checked"
                        @click="changeRadioButton(index, carpentry, carpentryOptions)"
                        :label="o.label"
                    />

                    <div v-if="carpentry.error" class="text-error mt-2">
                        {{ carpentry.error }}
                    </div>
                </div>


                <!-- outsideInsulationAfter2012 field -->
                <div v-if="type.value && type.value != 'office' && constructionPeriod.value && buildingAdditionalQuestionsConditions.includes(constructionPeriod.value.split('__')[0])">
                    <div class="survey-question">
                        Votre logement a-t-il été isolé par l’extérieur après 2012&nbsp;?
                    </div>

                    <RadioButton
                        v-for="o, index in outsideInsulationOptions"
                        :key="`outsideInsulationAfter2012${index}`"
                        :checked="o.checked"
                        @click="changeRadioButton(index, outsideInsulationAfter2012, outsideInsulationOptions)"
                        :label="o.label"
                    />

                    <div v-if="outsideInsulationAfter2012.error" class="text-error mt-2">
                        {{ outsideInsulationAfter2012.error }}
                    </div>
                </div>
                

                <!-- roofingInsulationAfter2012 field -->
                <div v-if="type.value && type.value != 'office' && type.value != 'apartment' && constructionPeriod.value && buildingAdditionalQuestionsConditions.includes(constructionPeriod.value.split('__')[0])">
                    <div class="survey-question">
                        La toiture de votre logement a-t-elle été isolée après 2012&nbsp;?
                    </div>

                    <RadioButton
                        v-for="o, index in roofingInsulationOptions"
                        :key="`roofingInsulationAfter2012${index}`"
                        :checked="o.checked"
                        @click="changeRadioButton(index, roofingInsulationAfter2012, roofingInsulationOptions)"
                        :label="o.label"
                    />

                    <div v-if="roofingInsulationAfter2012.error" class="text-error mt-2">
                        {{ roofingInsulationAfter2012.error }}
                    </div>
                </div>
            </div>


            <!-- global errors and next page button -->
            <div class="has-text-centered mt-5 mb-5">
                <div class="text-error mb-2">
                    {{ error }}
                </div>

                <a
                    class="button classic"
                    :class="{'is-loading': isLoading}"
                    @click="done"
                >
                    Suivant
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { ref, reactive } from 'vue';
import { useUserStore } from '@/stores/user';
import API from '@/services/api';
import RadioButton from '@/components/survey/RadioButton.vue';
import SurveyHeader from '@/components/survey/SurveyHeader.vue';
import SquareList from '@/components/survey/SquareList.vue';
import { processSurveyError, homeTypes } from '@/utils.js';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const isLoading = ref(false)

const error = ref(null)

const types = [...homeTypes]

const floorOptions = reactive([
    {
        "label": "Au dernier étage, sous combles non aménagés",
        "slug": "last_floor_under_attic",
        "checked": false
    }, {
        "label": "Au dernier étage, sous toit",
        "slug": "last_floor_under_roof",
        "checked": false
    }, {
        "label": "Étage intermédiaire",
        "slug": "middle_floor",
        "checked": false
    }, {
        "label": "Rez-de-chaussée",
        "slug": "ground_floor",
        "checked": false
    }
])
const constructionPeriodOptions = reactive([
    {
        "label": "Avant 1948",
        "slug": "old__before_1948",
        "checked": false
    }, {
        "label": "1949-1988",
        "slug": "recent__1949_1988",
        "checked": false
    }, {
        "label": "1989-2011",
        "slug": "recent__1989_2011",
        "checked": false
    }, {
        "label": "2012-2021",
        "slug": "new__2012_2021",
        "checked": false
    }, {
        "label": "Après 2021",
        "slug": "new__after_2021",
        "checked": false
    }, {
        "label": "Je ne sais pas",
        "slug": "unknown__unknown",
        "checked": false
    }
])
const buildingAdditionalQuestionsConditions = ['old', 'recent']

const carpentryOptions = reactive([
    {
        "label": "D'origine",
        "slug": "original",
        "checked": false
    }, {
        "label": "Remplacées avant 2012",
        "slug": "replaced_before_2012",
        "checked": false
    }, {
        "label": "Remplacées après 2012",
        "slug": "replaced_after_2012",
        "checked": false
    }, {
        "label": "Je ne sais pas",
        "slug": "unknown",
        "checked": false
    }
])
const outsideInsulationOptions = reactive([
    {
        "label": "Oui",
        "slug": "yes",
        "checked": false
    }, {
        "label": "Non",
        "slug": "no",
        "checked": false
    }, {
        "label": "Je ne sais pas",
        "slug": "unknown",
        "checked": false
    }
])
const roofingInsulationOptions = reactive([
    {
        "label": "Oui",
        "slug": "yes",
        "checked": false
    }, {
        "label": "Non",
        "slug": "no",
        "checked": false
    }, {
        "label": "Je ne sais pas",
        "slug": "unknown",
        "checked": false
    }
])

const mapTypeText = {
    'apartment': 'appartement',
    'office': 'bureau',
}

const type = ref({ error: null, value: null })
const floor = ref({ error: null, value: null })
const constructionPeriod = ref({ error: null, value: null })
const heatedSurface = ref({ error: null, value: null })
const carpentry = ref({ error: null, value: null })
const outsideInsulationAfter2012 = ref({ error: null, value: null })
const roofingInsulationAfter2012 = ref({ error: null, value: null })

const initOptions = (field, optionsObject) => {
    optionsObject.forEach(el => {
        if(el.slug == field.value) el.checked = true
    })
}

if (userStore.account.survey_data) {
    type.value.value = userStore.account.survey_data.type || null
    floor.value.value = userStore.account.survey_data.floor || null
    constructionPeriod.value.value = userStore.account.survey_data.construction_period || null
    heatedSurface.value.value = userStore.account.survey_data.surface || null
    carpentry.value.value = userStore.account.survey_data.carpentry || null
    outsideInsulationAfter2012.value.value = userStore.account.survey_data.outside_insulation_after_2012 || null
    roofingInsulationAfter2012.value.value = userStore.account.survey_data.roofing_insulation_after_2012 || null
    initOptions(floor.value, floorOptions)
    initOptions(constructionPeriod.value, constructionPeriodOptions)
    initOptions(carpentry.value, carpentryOptions)
    initOptions(outsideInsulationAfter2012.value, outsideInsulationOptions)
    initOptions(roofingInsulationAfter2012.value, roofingInsulationOptions)
}

const resetOptions = (field, optionsObject) => {
    field.value = null
    optionsObject.forEach((el, i) => {
        el.checked = false
    })
}

const updateType = (slug) => {
    type.value.value = slug
    if(slug == 'detached_house' || slug == 'terraced_house') {
        resetOptions(floor.value, floorOptions)
    }
    if(slug == 'office') {
        resetOptions(carpentry.value, carpentryOptions)
        resetOptions(outsideInsulationAfter2012.value, outsideInsulationOptions)
        resetOptions(roofingInsulationAfter2012.value, roofingInsulationOptions)
    }
    if(slug == 'apartment') {
        resetOptions(roofingInsulationAfter2012.value, roofingInsulationOptions)
    }
}

const changeRadioButton = (index, field, optionsObject) => {
    optionsObject.forEach((el, i) => {
        if(i == index) {
            el.checked = true
            field.value = el.slug
        }
        else el.checked = false
    })
    if (constructionPeriod.value.value && !buildingAdditionalQuestionsConditions.includes(constructionPeriod.value.value.split('__')[0])) {
        resetOptions(carpentry.value, carpentryOptions)
        resetOptions(outsideInsulationAfter2012.value, outsideInsulationOptions)
        resetOptions(roofingInsulationAfter2012.value, roofingInsulationOptions)
    }
}

const done = async() => {

    let payload = {
        'step1_done': true,
        'type': type.value.value,
        'floor': floor.value.value,
        'construction_period': constructionPeriod.value.value,
        'surface': heatedSurface.value.value,
        'carpentry': carpentry.value.value,
        'outside_insulation_after_2012': outsideInsulationAfter2012.value.value,
        'roofing_insulation_after_2012': roofingInsulationAfter2012.value.value
    }

    let hasError = false;

    if (type.value.value == null) {
        type.value.error = "Merci de sélectionner un type de logement."
        hasError = true
    } else {
        type.value.error = null
    }
    if (['office', 'apartment'].includes(type.value.value) && floor.value.value == null) {
        floor.value.error = "Merci de sélectionner un étage."
        hasError = true
    } else {
        floor.value.error = null
    }
    if (constructionPeriod.value.value == null) {
        constructionPeriod.value.error = "Merci de sélectionner une option."
        hasError = true
    } else {
        constructionPeriod.value.error = null
    }
    if (heatedSurface.value.value == null || !Number.isInteger(heatedSurface.value.value) || heatedSurface.value.value < 0) {
        heatedSurface.value.error = "Merci de renseigner un nombre entier positif."
        hasError = true
    } else {
        heatedSurface.value.error = null
    }
    if (type.value.value && type.value.value != 'office' && constructionPeriod.value.value && buildingAdditionalQuestionsConditions.includes(constructionPeriod.value.value.split('__')[0]) && carpentry.value.value == null) {
        carpentry.value.error = "Merci de sélectionner une option."
        hasError = true
    } else {
        carpentry.value.error = null
    }
    if (type.value.value && type.value.value != 'office' && constructionPeriod.value.value && buildingAdditionalQuestionsConditions.includes(constructionPeriod.value.value.split('__')[0]) && outsideInsulationAfter2012.value.value == null) {
        outsideInsulationAfter2012.value.error = "Merci de sélectionner une option."
        hasError = true
    } else {
        outsideInsulationAfter2012.value.error = null
    }
    if (type.value.value && type.value.value != 'office' && type.value.value != 'apartment' && constructionPeriod.value.value && buildingAdditionalQuestionsConditions.includes(constructionPeriod.value.value.split('__')[0]) && roofingInsulationAfter2012.value.value == null) {
        roofingInsulationAfter2012.value.error = "Merci de sélectionner une option."
        hasError = true
    } else {
        roofingInsulationAfter2012.value.error = null
    }

    console.log('done', payload)

    if (!hasError) {
        try {
            isLoading.value = true
            let resp = await API.pushSurveyData(userStore.account.id, payload)
            if (resp.status == 200) {
                userStore.trackEvent('click_next_step', {'page': route.path, 'success': true, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
                await userStore.update_profile()
                if(Object.keys(userStore.account.survey_data).includes('step1_done')){
                    router.push({ name: "Survey2" })
                } else {
                    router.push({ name: "Survey1" })
                }
            }
        } catch (e) {
            isLoading.value = false
            error.value = processSurveyError(e)
            userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3], 'error_type': error.value})
        }
    } else {
        let errorSet = ({
            "type": type.value.error || '',
            "floor": floor.value.error || '',
            "construction_period": constructionPeriod.value.error || '',
            "surface": heatedSurface.value.error || '',
            "carpentry": carpentry.value.error || '',
            "outside_insulation_after_2012": outsideInsulationAfter2012.value.error || '',
            "roofing_insulation_after_2012": roofingInsulationAfter2012.value.error || ''
        })
        userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3], 'error_type': JSON.stringify(errorSet)})
    }
    isLoading.value = false
}
</script>

<style scoped>

</style>
