import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import HomeRouter from '../views/HomeRouter.vue'
import SignUp from '../views/Auth/SignUp.vue'
import SignUpDone from '../views/Auth/SignUpDone.vue'
import SignUpEmbeddedTypeForm from '../views/Auth/EmbeddedTypeForm.vue'
import ConnectionInProgress from '../views/Auth/ConnectionInProgress.vue'
import ConnectionLink from '../views/Auth/ConnectionLink.vue'
import Parameters from '../views/Parameters.vue'
import Profile from '../views/Profile.vue'
import LegalInformations from '../views/LegalInformations.vue'
import Survey1 from '../views/Survey/Survey1.vue'
import Survey2 from '../views/Survey/Survey2.vue'
import Survey3 from '../views/Survey/Survey3.vue'
import Survey4 from '../views/Survey/Survey4.vue'
import Survey5 from '../views/Survey/Survey5.vue'
import Survey6 from '../views/Survey/Survey6.vue'
import Survey7 from '../views/Survey/Survey7.vue'
import SurveyEmbeddedTypeForm from '../views/Survey/EmbeddedTypeForm.vue'
import Conso from '../views/Conso.vue'
import ActionsList from '../views/ActionsList.vue'
import MetaActionIdentifyThermalLeaks from '../views/MetaActions/MetaActionIdentifyThermalLeaks.vue'
import MetaActionIdentifyWaysToSave from '../views/MetaActions/MetaActionIdentifyWaysToSave.vue'
import MetaActionIdentifyWaysToSaveHotWater from '../views/MetaActions/MetaActionIdentifyWaysToSaveHotWater.vue'
import MetaEnd from '../views/MetaActions/MetaEnd.vue'
import Action from '../views/Action.vue'
import NotFunctionalPrm from '../views/NotFunctionalPrm.vue'
import WaitingScreen1 from '../views/WaitingScreens/WaitingScreen1.vue'
import WaitingScreen2 from '../views/WaitingScreens/WaitingScreen2.vue'
import WaitingScreen3 from '../views/WaitingScreens/WaitingScreen3.vue'
import WaitingScreen4 from '../views/WaitingScreens/WaitingScreen4.vue'
import WaitingScreen5 from '../views/WaitingScreens/WaitingScreen5.vue'
import WaitingScreenGas from '../views/Gas/WaitingScreenGas.vue'
import EmbeddedHomeys from '../views/Gas/EmbeddedHomeys.vue'
import GasSignUpDone from '../views/Gas/SignUpDone.vue'
import Thermostat from '../views/Thermostat.vue'
import Dashboard from '../views/Dashboard.vue'
import API from '@/services/api'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'HomeRouter',
            meta: { requireAuth: false },
            component: HomeRouter,
        }, {
            path: '/login',
            redirect: '/inscription'
        }, {
            path: '/inscription',
            name: 'SignUp',
            meta: { requireAuth: false },
            component: SignUp,
        }, {
            path: '/connexion',
            name: 'ConnectionLink',
            meta: { requireAuth: false },
            component: ConnectionLink,
        }, {
            path: '/ww-connexion/:uib64/:token', // if this is updated, an update in the backend is necessary, see views.accounts.AccountViewSet.get_connection_link()
            name: 'ConnectionInProgress',
            meta: { requireAuth: false },
            component: ConnectionInProgress,
        }, {
            path: '/inscription-termine',
            redirect: '/inscription-reussie'
        }, {
            path: '/inscription-reussie',
            name: 'SignupDone',
            meta: { requireAuth: true, surveyProcess: true },
            component: SignUpDone,
        }, {
            path: '/eligibilite-tc',
            name: 'SignUpEmbeddedTypeForm',
            meta: { requireAuth: true, surveyProcess: true },
            component: SignUpEmbeddedTypeForm
        }, {
            path: '/enquete-etape-1',
            name: 'Survey1',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: Survey1,
        }, {
            path: '/enquete-etape-2',
            name: 'Survey2',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: Survey2
        }, {
            path: '/enquete-etape-3',
            name: 'Survey3',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: Survey3
        }, {
            path: '/enquete-etape-4',
            name: 'Survey4',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: Survey4
        }, {
            path: '/enquete-etape-5',
            name: 'Survey5',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: Survey5
        }, {
            path: '/enquete-etape-6',
            name: 'Survey6',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: Survey6
        }, {
            path: '/enquete-etape-7',
            name: 'Survey7',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: Survey7
        }, {
            path: '/enquete-etape-8',
            name: 'SurveyEmbeddedTypeForm',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: SurveyEmbeddedTypeForm
        }, {
            path: '/pas-de-donnees',
            name: 'NotFunctionalPrm',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: NotFunctionalPrm
        }, {
            path: '/fin-questionnaire',
            name: 'WaitingScreen1',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: WaitingScreen1
        }, {
            path: '/quiz-1-question',
            name: 'WaitingScreen2',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: WaitingScreen2
        }, {
            path: '/quiz-1-reponse',
            name: 'WaitingScreen3',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: WaitingScreen3
        }, {
            path: '/quiz-2-question',
            name: 'WaitingScreen4',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: WaitingScreen4
        }, {
            path: '/quiz-2-reponse',
            name: 'WaitingScreen5',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: WaitingScreen5
        }, {
            path: '/vers-homeys',
            name: 'WaitingScreenGas',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: WaitingScreenGas
        }, {
            path: '/homeys',
            name: 'EmbeddedHomeys',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: EmbeddedHomeys
        }, {
            path: '/partner/homeys',
            name: 'GasSignUpDone',
            meta: { requireAuth: true, consentRequired: true, surveyProcess: true },
            component: GasSignUpDone
        }, {
            path: '/accueil',
            name: 'Dashboard',
            component: Dashboard,
            meta: { requireAuth: true, consentRequired: true, available: true },
        }, {
            path: '/synthese-enquete',
            redirect: '/parametres'
        }, {
            path: '/parametres',
            name: 'Parameters',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: Parameters
        }, {
            path: '/profil',
            name: 'Profile',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: Profile
        }, {
            path: '/informations-legales',
            name: 'LegalInformations',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: LegalInformations
        }, {
            path: '/ma-consommation',
            redirect: '/consommation'
        }, {
            path: '/consommation',
            name: 'Conso',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: Conso
        }, {
            path: '/mes-univers',
            redirect: '/actions'
        }, {
            path: '/actions',
            name: 'ActionsList',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: ActionsList
        }, {
            path: '/identifier-les-fuites-thermiques',
            name: 'MetaActionIdentifyThermalLeaks',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: MetaActionIdentifyThermalLeaks,
            props: route => ({ actionPk: "" })
        }, {
            path: '/economiser-sur-l-electromenager',
            name: 'MetaActionIdentifyWaysToSave',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: MetaActionIdentifyWaysToSave,
            props: route => ({ actionPk: "" })
        }, {
            path: '/traquer-les-surconsommations-d-eau-chaude',
            name: 'MetaActionIdentifyWaysToSaveHotWater',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: MetaActionIdentifyWaysToSaveHotWater,
            props: route => ({ actionPk: "" })
        }, {
            path: '/fin-questionnaire',
            name: 'MetaEnd',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: MetaEnd
        }, {
            path: '/action/:pk',
            name: 'Action',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: Action,
            props: {pk: ""}
        }, {
            path: '/gestion-des-thermostats',
            name: 'Thermostat',
            meta: { requireAuth: true, consentRequired: true, available: true },
            component: Thermostat
        }, { 
            path: '/:pathMatch(.*)*',
            redirect: '/'
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    }
})

router.beforeEach(async function(to, from, next){
    let userStore = useUserStore()
    let isTokenValid = false
    try {
        isTokenValid = await userStore.hasValidToken()
    } catch (e) {
        console.log("before each hasValidToken error", e)
    }
    console.log('before isTokenValid', isTokenValid)
    // console.log("beforeEach", from.path, to.path, "requireAuth",
    //    to.meta.requireAuth, "isAuthenticated", userStore.isAuthenticated)
    console.log("1")
    if (isTokenValid) {
        await userStore.update_profile()
        console.log("Token is valid", to)
        if (!to.meta.requireAuth) {
            if (to.name != 'HomeRouter') {
                next({ name: 'HomeRouter' })
                console.log("2")
            } else {
                next()
                console.log("3")
            }
        } else {
            if (!userStore.isAuthenticated) {
                console.log("4")
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                next({ name: 'SignUp' })
            } else if (to.meta.consentRequired && userStore.account && !userStore.account.valid_consent) {
                console.log("5")
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                next({ name: 'SignUp' })
            } else if (
                to.name != 'NotFunctionalPrm' &&
                userStore.account &&
                !userStore.account.has_functional_prm
            ) {
                console.log("6")
                next({ name: 'NotFunctionalPrm' })
            } else if (
                !to.meta.surveyProcess &&
                userStore.account &&
                !userStore.account.survey_done
            ) {
                console.log("7")
                next({ name: 'Survey1' })
            } else {
                console.log("8")
                next()
            }
        }
    } else {
        if (!to.meta.requireAuth) {
            next()
        }
        else next({ name: 'SignUp' })
    }
})

router.afterEach((to, from) => {
    // patch to avoid error loop 401 unauthorized
    if(to.meta.requireAuth) {
        API.sendToAnalytics({
            'message_type': 'route_changed',
            'target_url': to.fullPath
        }).then((accept) => {
            // console.log("monitoring ok", accept)
        }, (reject) => {
            console.log("monitoring error : ", reject.message)
        })
        
    }
})

export default router
