<template>
        <div id="homeys"></div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore()

//- stage params
// url https://widget-consent-staging.homeys.io
// token 8728cc95

const callWidget = (firstname, lastname, username, building_address, building_usage) => {
    (function(e,t,n,a) {
        const o=document.createElement("script");
        o.src=`${e}/public/scripts/widget-loader.js`,
        o.async=!0,
        o.onload=()=>{
            window.MyWidget&&window.MyWidget.init({containerId:n,token:t,params:a})
        },
        document.head.appendChild(o)
    })('https://widget-consent.homeys.io', '7e098949', 'homeys', {
        firstname: firstname,
        lastname: lastname,
        username: username,
        building_address: building_address,
        building_name: 'Mon logement',
        building_usage: building_usage,
        width: "full",
        shadow: false
    });
}

onMounted(() => {
    let firstname = userStore.account.user.first_name
    let lastname = userStore.account.user.last_name
    let username = userStore.account.user.username
    let building_address = `${userStore.account.enedis_address} ${userStore.account.enedis_zipcode} ${userStore.account.enedis_city_name}`
    let building_usage = ['house', 'terraced_house', 'detached_house'].includes(userStore.account.survey_data.type) ? 'maison_individuelle' : 'immeuble_habitation_prive'
    console.log("Widget ", firstname, lastname, username, building_address, building_usage)
    callWidget(firstname, lastname, username, building_address, building_usage)
})

</script>