<template>
    <div class="survey-container has-text-primary">
        <div class="survey-inner-container">
            <div>
                <div class="survey-step">Étape 7/7</div>
                <SurveyHeader>
                    <template #title>
                        <span class="survey-title">Avez-vous réalisé une rénovation ces 2 dernières années&nbsp;?</span>
                    </template>
                </SurveyHeader>

                <!-- renovation field -->
                <RadioButton
                    label="Oui"
                    :checked="renovation.value === true"
                    @click="renovation.value = true;"
                />
                <RadioButton
                    label="Non"
                    :checked="renovation.value === false"
                    @click="renovation.value = false;"
                />

                <div class="text-error mb-4" v-if="renovation.error">{{ renovation.error }}</div>
            </div>

            <!-- global errors and next page button -->
            <div class="has-text-centered mt-5 mb-5">
                <div class="text-error mb-2">
                    {{ error }}
                </div>
                
                <a
                    class="button classic"
                    :class="{'is-loading': isLoading}"
                    @click="done"
                >
                    Suivant
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, onMounted } from 'vue';
import API from '@/services/api';
import SurveyHeader from '@/components/survey/SurveyHeader.vue';
import RadioButton from '@/components/survey/RadioButton.vue';
import { processSurveyError } from '@/utils.js';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const isLoading = ref(false)

const error = ref(null)

const renovation = ref({ error: null, value: null})

if (userStore.account.survey_data) {
    renovation.value.value = userStore.account.survey_data.renovation
}

const done = async() => {

    let payload = {
        'step7_done': true,
        'renovation': renovation.value.value,
    }

    let hasError = false;

    if (renovation.value.value == null) {
        renovation.value.error = "Merci de sélectionner une option."
        hasError = true
    } else {
        renovation.value.error = null
    }

    console.log('done', payload)

    if(!hasError) {
        try {
            isLoading.value = true
            let resp = await API.pushSurveyData(userStore.account.id, payload)
            if (resp.status == 200) {
                userStore.trackEvent('click_next_step', {'page': route.path, 'success': true, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
                await userStore.update_profile()
                if(Object.keys(userStore.account.survey_data).includes('step7_done')){
                    if(renovation.value.value){
                        // Redirect to embed typeform for renovation
                        console.log("REDIRECT TO RENOVATION TYPEFORM")
                        router.push({ name: "SurveyEmbeddedTypeForm" })
                    } else if (userStore.hasGas) {
                        // Redirect to special page for gas process
                        console.log("REDIRECT TO GAS ")
                        router.push({ name: "WaitingScreenGas" })
                    } else {
                        router.push({ name: "WaitingScreen1" })
                    }
                } else {
                    router.push({ name: "Survey7" })
                }
            }
        } catch (e) {
            isLoading.value = false
            error.value = processSurveyError(e)
            userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3], 'error_type': error.value})
        }
    } else {
        let errorSet = ({
            "renovation": renovation.value.error || ''
        })
        userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3], 'error_type': JSON.stringify(errorSet)})
    }
    isLoading.value = false
}

onMounted(() => {
    if(!Object.keys(userStore.account.survey_data).includes('step6_done')){
        router.push({ name: "Survey6" })
    }
})
</script>

<style scoped>

</style>
