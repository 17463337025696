<template>
    <div class="my-container has-text-centered">
        <div class="flexcol" style="justify-content: space-between; flex-grow: 1;">
            <div>
                <QuestionCard
                    :question-array="['D’après vous, en moyenne,','un bain équivaut à :']"
                    bg-color="bg-blue-5"
                />
                <div style="background: linear-gradient(var(--winter-blue-5) 100px, white 100px)">
                    <div class="white-card-quiz">
                        <div v-for="resp, index in responseSet">
                            <RadioButton
                                :key="`question1-${index}`"
                                :label="resp.label"
                                :checked="resp.checked"
                                :size="19"
                                :outline-size="2"
                                :margin-top="2"
                                @click="updateUserResponse(index)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="next-bloc-quiz">
                <div v-if="error" class="text-error">{{ error }}</div>
                <div
                    class="has-text-white button is-primary height-48 radius-10 has-text-weight-bold"
                    style="width: 100%;"
                    @click="next">
                    Suivant
                </div>
            </div>
        </div>
        
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, reactive, onBeforeMount } from 'vue';
import API from '@/services/api';
import RadioButton from '@/components/survey/RadioButton.vue';
import { quiz1ResponseSet, quiz1RightAnswer, processSurveyError, deepCopy } from '@/utils.js';
import QuestionCard from '@/components/waitingScreens/QuestionCard.vue';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const error = ref(null)

const responseSet = reactive(deepCopy(quiz1ResponseSet))
const userResponse = ref(null)

const updateUserResponse = (index) => {
    responseSet.forEach((el, i) => {
        if(i == index) {
            el.checked = true
            userResponse.value = el.slug
        }
        else el.checked = false
    })
}

const next = async() => {
    let payload = {
        'shower_duration': userResponse.value
    }

    if (userResponse.value != null) {
        try {
            let resp = await API.pushQuizData(userStore.account.id, payload)
            if (resp.status == 200) {
                userStore.trackEvent('click_next_step', {'page': route.path, 'right_answer': quiz1RightAnswer.slug == userResponse.value, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
                await userStore.update_profile()
                router.push({ name: 'WaitingScreen3' })
            }
        } catch(e) {
            error.value = processSurveyError(e)
        }
    } else {
        error.value = "Veuillez choisir une option"
    }
}

onBeforeMount(() => {
    console.log("WaitingScreen2")
    if (userStore.account.quiz_data && userStore.account.quiz_data.shower_duration != undefined) {
        responseSet.forEach(r => {
            if (r.slug == userStore.account.quiz_data.shower_duration) {
                r.checked = true
                userResponse.value = r.slug
            }
        })
    }
})

</script>

<style scoped>
.my-container {
    max-width: 600px;
    min-width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
}


</style>