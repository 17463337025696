<template>
    <div class="flexcol" style="height: 100vh;">
        <iframe
            :src="formUrl"
            width="100%"
            height="100%"
            frameborder="0"
        >
        </iframe>
    </div>
    
</template>

<script setup>
import { computed } from 'vue';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore()

// Embedded Typeform for Connected Thermostat survey
const formUrl = computed(() => {
    return userStore.getTcUrl("embedded")
})


</script>