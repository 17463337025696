<template>
    <div class="my-container has-text-centered">
        <div class="flexcol" style="justify-content: space-between; flex-grow: 1;">
            <div>
                <QuestionCard
                    :question-array="['D’après vous, en moyenne,','un bain équivaut à :']"
                    :bg-color="userResult ? 'bg-green-3' : 'bg-yellow-2'"
                />

                <AnswerWhiteCard
                    :user-result="userResult"
                    :user-answer="userAnswer"
                    :right-answer="quiz1RightAnswer"
                />
                
                <div style="margin-top: 20px; margin-bottom: 20px;">
                    <img src="/src/assets/img/quiz_shower.svg" />
                </div>
                <div class="font-size-20 line-height-1-2" style="padding: 0 20px;">
                    <div v-if="userResult" class="flexcol text-green-1 has-text-weight-bold">
                        <span>Bingo !</span>
                        <span>Un bain est bien l'équivalent d'une douche de 28 minutes.</span>
                    </div>
                    <div v-else class="flexcol text-blue-2 has-text-weight-bold">
                        <span>Vous y êtes presque,</span>
                        <span>un bain est l’équivalent d’une douche de 28 minutes.</span>
                    </div>
                </div>
            </div>
            <div class="next-bloc-quiz">
                <div v-if="error" class="text-error">{{ error }}</div>
                <div
                    class="has-text-white button is-primary height-48 radius-10 has-text-weight-bold"
                    style="width: 100%;"
                    @click="next">
                    Suivant
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, computed, onMounted, onBeforeMount } from 'vue';
import { quiz1ResponseSet, quiz1RightAnswer } from '@/utils.js';
import QuestionCard from '@/components/waitingScreens/QuestionCard.vue';
import AnswerWhiteCard from '@/components/waitingScreens/AnswerWhiteCard.vue';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const error = ref(null)

const userAnswer = ref(null)
const userResult = computed(() => {
    return userAnswer.value != null ? quiz1RightAnswer.slug === userAnswer.value.slug : false
})

const next = () => {
    userStore.trackEvent('click_next_step', {'page': route.path, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
    router.push({ name: 'WaitingScreen4' })
}

onBeforeMount(() => {
    console.log("WaitingScreen3")
    if (userStore.account.quiz_data && userStore.account.quiz_data.shower_duration != undefined) {
        quiz1ResponseSet.forEach(r => {
            if (r.slug == userStore.account.quiz_data.shower_duration) {
                userAnswer.value = r
            }
        })
    } else {
        router.push({ name: 'WaitingScreen2' })
    }
    console.log("UserAnswer", userAnswer.value)
})

</script>

<style scoped>
.my-container {
    max-width: 600px;
    min-width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white;
}


</style>