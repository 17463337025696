<template>
    <div class="my-container">
        <div style="padding: 20px;">
            <AlertStack />
            <div class="title-page">
                Ma consommation
            </div>

            <!-- my home info card -->
            <MyHome :survey="userStore.account.survey_data"/>

            <!-- periodicity choice -->
            <div class="flexrow bg-blue-5 radius-14 height-56 is-centered p-1 mb-3 mt-4">
                <a class="button radius-10 height-48 has-text-primary has-text-weight-bold font-size-14"
                    :class="{ 'is-white': periodType == 'daily', 'button-blue': periodType != 'daily' }"
                    style="flex: 1;" @click="setPeriodType('daily')">
                    Jour
                </a>
                <a class="button radius-10 height-48 has-text-primary has-text-weight-bold font-size-14"
                    :class="{ 'is-white': periodType == 'weekly', 'button-blue': periodType != 'weekly' }"
                    style="flex: 1;" @click="setPeriodType('weekly')">
                    Semaine
                </a>
                <a class="button radius-10 height-48 has-text-primary has-text-weight-bold font-size-14"
                    :class="{ 'is-white': periodType == 'monthly', 'button-blue': periodType != 'monthly' }"
                    style="flex: 1;" @click="setPeriodType('monthly')">
                    Mois
                </a>
                <a class="button radius-10 height-48 has-text-primary has-text-weight-bold font-size-14"
                    :class="{ 'is-white': periodType == 'yearly', 'button-blue': periodType != 'yearly' }"
                    style="flex: 1;" @click="setPeriodType('yearly')">
                    Année
                </a>
            </div>

            <!-- date choice -->
            <div class="flexrow has-text-centered">
                <a class="button is-light button-blue radius-10 pl-3 pr-3" style="height: 40px; width: 40px;" @click="previousPeriod">
                    <font-awesome-icon icon="fa-solid fa-arrow-left has-text-primary" />
                </a>
                <div style="flex-grow: 1; align-content: center; height: 40px;"
                    class="ml-3 mr-3 control border-blue radius-10 has-text-primary">
                    <span v-if="periodType == 'monthly' || periodType == 'daily'" style="text-transform: capitalize;">
                        {{ prettyPeriodDate() }}
                    </span>
                    <span v-else>
                        {{ prettyPeriodDate() }}
                    </span>
                </div>
                <a class="button is-light button-blue radius-10 pl-3 pr-3" style="height: 40px; width: 40px;" @click="nextPeriod">
                    <font-awesome-icon icon="fa-solid fa-arrow-right has-text-primary" />
                </a>
            </div>

            <!-- bar graph -->
            <div :style="`min-height: ${hasDetailData == false && !isLoadingConso ? '80' : '420'}px;`">
                <div v-show="price != null && hasDetailData == true" class="my-3">
                    <div class="flexrow" style="justify-content: space-between;">
                        <div class="font-size-12" style="color: #092754; opacity: 0.5;">euros</div>
                        <img src="/src/assets/img/info_icon.svg" class="is-clickable mr-3" style="height: 23px; width: 23px;" @click="openModalInfoAboutConsumption"/>
                    </div>

                    <canvas id="energyChart" height="300" style="min-height: 300px !important;"></canvas>

                    <div class="flexrow font-size-12 has-text-primary has-text-weight-medium line-height-1-2" style="margin-top: 12px;">
                        <div class="flexrow" style="flex: 1; gap: 5px;">
                            <img src="/src/assets/img/conso_legend_dash.svg" style="width: 28px;"/>
                            <div class="flexcol has-text-weight-medium ml-1">
                                <span>Conso</span>
                                <span>prévue</span>
                            </div>
                        </div>
                        <div class="flexrow" style="flex: 1; gap: 5px; align-items: center;">
                            <div class="my-dot inf"></div>
                            <div class="flexcol has-text-weight-medium ml-1">
                                <span>Inférieure</span>
                                <span>à la prévision</span>
                            </div>
                        </div>
                        <div class="flexrow" style="flex: 1; gap: 5px; align-items: center;">
                            <div class="my-dot sup"></div>
                            <div class="flexcol has-text-weight-medium ml-1">
                                <span>Supérieure</span>
                                <span>à la prévision</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="hasGas" class="flexrow font-size-12 has-text-primary has-text-weight-medium line-height-1-2" style="margin-top: 12px; justify-content: space-around;">
                        <div class="flexrow" style="gap: 5px; align-items: center;">
                            <div class="my-pill elec" :class="{'elec': hasGas}"></div>
                            <div class="flexcol has-text-weight-medium ml-1">
                                <span>Électricité</span>
                            </div>
                        </div>
                        <div class="flexrow" style="gap: 5px; align-items: center;">
                            <div class="my-pill gas" :class="{'gas': hasGas}"></div>
                            <div class="flexcol has-text-weight-medium ml-1">
                                <span>Gaz</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="hasDetailData == false" class="my-5">
                    <div v-if="isLoadingConso" class="flexcol has-text-primary" style="width: 100%; text-align: center;">
                        <span>Chargement du graphique</span>
                        <button class="button is-loading is-white" disabled></button>
                    </div>
                    <!-- in case we don't have any datas -->
                    <div v-else class="mt-4 mb-4">
                        <div v-if="periodType == 'daily' && error_code == null" class="has-text-primary has-text-centered">
                            <h3 class="has-text-weight-bold mb-3">
                                On fait au plus vite !
                            </h3>
                            <h2 class="line-height-1-1">
                                Vos données de consommation horaires sont disponibles le lendemain de votre inscription.
                            </h2>
                        </div>
                        <div v-else class="has-text-primary has-text-centered">
                            <h3 class="has-text-weight-bold mb-3">
                                Oh non !
                            </h3>
                            <h2 class="line-height-1-1">
                                Nous n'avons pas de données pour cette période.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <!-- doughnut -->
            <Doughnut
                v-show="!isLoadingActions"
                :actions="allActions"
                :elec-indicators="userStore.account.diagnostic_indicators"
                :gas-indicators="userStore.account.gas_diagnostic_indicators"
                :elec-price="userStore.account.elec_price"
                :gas-price="userStore.account.gas_price"
                :consumed-energy="userStore.consumedEnergy"
                @toggle-doughnut-info="openModalInfoAboutDoughnut"
            />

            <!-- footer -->
            <div class="flexcol text-blue-2 has-text-weight-semibold line-height-1-2 has-text-centered" style="margin: 20px 0;">
                <span>Découvrez nos recommandations</span>
                <span>pour réduire votre facture&nbsp;:</span>
            </div>

            <div
                class="has-text-white button is-primary height-48 radius-10 has-text-weight-bold mt-0"
                style="width: 100%; margin-bottom: 70px;"
                @click="goToActionsPage">
                Découvrir mes actions
            </div>
        </div>

        <ModalInfoAboutConsumption  :active="showModalInfoAboutConsumption" @toggle-info="showModalInfoAboutConsumption = !showModalInfoAboutConsumption" />
        <ModalInfoAboutDoughnut  :active="showModalInfoAboutDoughnut" @toggle-info="showModalInfoAboutDoughnut = !showModalInfoAboutDoughnut" />
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { useAlertsStore } from "@/stores/alert";
import API from '@/services/api';
import ModalInfoAboutConsumption from "@/components/modals/ModalInfoAboutConsumption.vue";
import ModalInfoAboutDoughnut from "@/components/modals/ModalInfoAboutDoughnut.vue";
import MyHome from "@/components/MyHome.vue";
import Doughnut from "@/components/Doughnut.vue";
import AlertStack from "@/components/AlertStack.vue";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

const userStore = useUserStore()
const alertsStore = useAlertsStore()
const router = useRouter()
const route = useRoute()
const isLoadingConso = ref(false)
const hasGas = userStore.account.has_gas_data

let allActions = []
const isLoadingActions = ref(false)

let selectedPeriodDay = ref(null)

//let minDatePeriod = ref(moment().startOf("month").format("YYYY-MM-DD"))
//let maxDatePeriod = ref(moment().endOf("month").format("YYYY-MM-DD"))
let minDatePeriod = ref(null)
let maxDatePeriod = ref(null)

let price = ref(null)
// let energy = ref(null)
// let ratio = ref(null)
let periodType = ref("weekly")
// let deltaPrice = ref(null)
let chart = ref(null)
// when detail graph is clicked:
let detailEnergy = ref(null)
let detailPrice = ref(null)
let hasDetailData = ref(false)
let error_code = ref(null)
// detailed data:
let detailed_dates = ref(null)
let detailed_real_consumption_euros = ref(null)
let detailed_reference_consumption_euros = ref(null)
// let detailed_real_consumption = ref(null)
// let detailed_reference_consumption = ref(null)
let gas_detailed_real_consumption_euros = ref(null)
let gas_detailed_reference_consumption_euros = ref(null)
let total_detailed_reference_consumption_euros = ref(null)

let barBackgroundColor = ref("rgba(16, 107, 207, 0.8)")

const showModalInfoAboutConsumption = ref(false)
const showModalInfoAboutDoughnut = ref(false)

const openModalInfoAboutConsumption = () => {
    userStore.trackEvent('click_tooltip', {'page': route.path, 'tooltip_name': 'conso_bar'})
    showModalInfoAboutConsumption.value = true
}

const openModalInfoAboutDoughnut = () => {
    userStore.trackEvent('click_tooltip', {'page': route.path, 'tooltip_name': 'conso_pie'})
    showModalInfoAboutDoughnut.value = true
}

const goToActionsPage = () => {
    userStore.trackEvent('click_see_actions', {'page': route.path})
    router.push({name: 'ActionsList'})
}

const setPeriodType = (value) => {
    periodType.value = value
    minDatePeriod.value = null
    maxDatePeriod.value = null

    getConso()
}

const prettyPeriodDate = function () {
    if (!minDatePeriod.value) {
        return ""
    }
    if (periodType.value == "daily") {
        if (minDatePeriod.value) {
            return moment(minDatePeriod.value).format("dddd D MMM YYYY")
        } else {
            return "Recherche de données..."
        }
    }
    if (periodType.value == "weekly") {
        return `Semaine du ${moment(minDatePeriod.value).format("D MMM YYYY")}`
    }
    if (periodType.value == "monthly") {
        return moment(minDatePeriod.value).format("MMMM YYYY")
    }
    if (periodType.value == "yearly") {
        return moment(minDatePeriod.value).format("YYYY")
    }
}

const previousPeriod = function () {
    // compute previous period
    console.log(periodType.value)
    if (minDatePeriod.value) {
        if (periodType.value == "daily") {
            let currentPeriodDate = moment(minDatePeriod.value)
            currentPeriodDate = currentPeriodDate.add(-1, "days")
            minDatePeriod.value = currentPeriodDate.format('YYYY-MM-DD')
            maxDatePeriod.value = currentPeriodDate.format('YYYY-MM-DD')
        }
        if (periodType.value == "weekly") {
            minDatePeriod.value = moment(minDatePeriod.value).add(-1, "weeks").format("YYYY-MM-DD")
            maxDatePeriod.value = moment(maxDatePeriod.value).add(-1, "weeks").format("YYYY-MM-DD")
        }
        if (periodType.value == "monthly") {
            minDatePeriod.value = moment(minDatePeriod.value).add(-1, "months").format("YYYY-MM-DD")
            maxDatePeriod.value = moment(maxDatePeriod.value).add(-1, "months").format("YYYY-MM-DD")
        }
        if (periodType.value == "yearly") {
            minDatePeriod.value = moment(minDatePeriod.value).add(-1, "years").format("YYYY-MM-DD")
            maxDatePeriod.value = moment(maxDatePeriod.value).add(-1, "years").format("YYYY-MM-DD")
        }
    }

    // fetch data for that date
    getConso()

    // hide cliked point
    detailEnergy.value = null
    detailPrice.value = null
}

const nextPeriod = function () {
    const currentDate = moment()

    // compute previous period
    if (periodType.value == "daily") {
        const newDatePeriod = moment(minDatePeriod.value).add(1, "days")
        if (newDatePeriod < currentDate) {
            minDatePeriod.value = newDatePeriod.format('YYYY-MM-DD')
            maxDatePeriod.value = newDatePeriod.format('YYYY-MM-DD')
        }
    }
    if (periodType.value == "weekly") {
        const newDatePeriod = moment(minDatePeriod.value).add(1, "weeks")
        if (newDatePeriod < currentDate) {
            minDatePeriod.value = newDatePeriod.format("YYYY-MM-DD")
            maxDatePeriod.value = moment(maxDatePeriod.value).add(1, "weeks").format("YYYY-MM-DD")
        }
    }
    if (periodType.value == "monthly") {
        const newDatePeriod = moment(minDatePeriod.value).add(1, "months")
        if (newDatePeriod < currentDate) {
            minDatePeriod.value = newDatePeriod.format("YYYY-MM-DD")
            maxDatePeriod.value = moment(maxDatePeriod.value).add(1, "months").format("YYYY-MM-DD")
        }
    }
    if (periodType.value == "yearly") {
        const newDatePeriod = moment(minDatePeriod.value).add(1, "years")
        if (newDatePeriod < currentDate) {
            minDatePeriod.value = newDatePeriod.format("YYYY-MM-DD")
            maxDatePeriod.value = moment(maxDatePeriod.value).add(1, "years").format("YYYY-MM-DD")
        }
    }

    // fetch data for that date
    getConso()

    // hide cliked point
    detailEnergy.value = null
    detailPrice.value = null
}

const computeBarColor = function (real, reference) {
    // if (real > reference) {
    //     return ['#E88306', '#FFC466']
    // } else {
    //     return ['#468300', '#8CCB57']
    // }
    return ['#96BEE2', '#FFC466']
}

const getConso = async() => {
    detailPrice.value = null
    detailEnergy.value = null
    error_code.value = null
    isLoadingConso.value = true
    
    try {
        console.log("get conso for range:", minDatePeriod.value, maxDatePeriod.value)
        let resp = await API.getIndicators(
            userStore.account.id,
            periodType.value,
            minDatePeriod.value,
            maxDatePeriod.value
        )
        console.log("data", resp.data)
        if (resp.data.ok) {
            selectedPeriodDay.value = resp.data.data.day
            price.value = resp.data.data.consumption_euros
            // deltaPrice.value = resp.data.data.delta_consumption_euros
            // energy.value = resp.data.data.consumption_kwh
            // ratio.value = resp.data.data.ratio_to_reference
            minDatePeriod.value = resp.data.data.min_period_date
            maxDatePeriod.value = resp.data.data.max_period_date

            // update the chart
            detailed_dates = resp.data.data.detailed_dates
            detailed_real_consumption_euros = resp.data.data.detailed_real_consumption_euros
            detailed_reference_consumption_euros = resp.data.data.detailed_reference_consumption_euros
            gas_detailed_real_consumption_euros = resp.data.data.gas_detailed_real_consumption_euros
            gas_detailed_reference_consumption_euros = resp.data.data.gas_detailed_reference_consumption_euros
            total_detailed_reference_consumption_euros = resp.data.data.total_detailed_reference_consumption_euros

            console.log("detailed_dates", detailed_dates)
            console.log("detailed_real_consumption_euros", detailed_real_consumption_euros)
            console.log("GAS detailed_real_consumption_euros", gas_detailed_real_consumption_euros)
            console.log("detailed_reference_consumption_euros", detailed_reference_consumption_euros)
            console.log("GAS detailed_reference_consumption_euros", gas_detailed_reference_consumption_euros)
            console.log("total_detailed_reference_consumption_euros", total_detailed_reference_consumption_euros)

            if (detailed_real_consumption_euros.length > 0) {
                hasDetailData.value = true
            } else {
                hasDetailData.value = false
            }

            chart.data.labels = detailed_dates
            chart.data.datasets[0].data = total_detailed_reference_consumption_euros
            chart.data.datasets[1].data = detailed_real_consumption_euros
            chart.data.datasets[2].data = gas_detailed_real_consumption_euros
            chart.data.datasets[1].borderWidth = new Array(detailed_dates.length).fill(0)

            // const barColors = new Array(detailed_dates.length).fill(barBackgroundColor.value)
            // const barColorsGas = new Array(detailed_dates.length).fill(barBackgroundColor.value)
            // for (var i = 0; i < detailed_real_consumption_euros.length; i++) {
            //     const gasEuros = gas_detailed_real_consumption_euros[i] == undefined ? 0 : gas_detailed_real_consumption_euros[i]
            //     [barColors[i], barColorsGas[i]] = computeBarColor(
            //         detailed_real_consumption_euros[i] + gasEuros,
            //         total_detailed_reference_consumption_euros[i],
            //     )
            // }

            // chart.data.datasets[1].backgroundColor = barColors
            chart.data.datasets[1].backgroundColor = '#96BEE2'
            // chart.data.datasets[2].backgroundColor = barColorsGas
            chart.data.datasets[2].backgroundColor = '#FFC466'
            chart.update()
        } else {
            error_code.value = resp.data.error_code
            price.value = null
            // energy.value = null
            // ratio.value = null
            hasDetailData.value = false
            minDatePeriod.value = resp.data.min_period_date
            maxDatePeriod.value = resp.data.max_period_date
            if (!minDatePeriod.value) {
                minDatePeriod.value = moment().format("YYYY-MM-DD")
                maxDatePeriod.value = moment().format("YYYY-MM-DD")
            }
        }
    } catch (e) {
        console.log("err", e)
        price.value = null
        // energy.value = null
        // ratio.value = null
        hasDetailData.value = false
    }
    isLoadingConso.value = false
}

const data = {
    labels: [],
    datasets: [
        {
            data: [],
            type: "line",
            borderColor: "rgba(9, 39, 84, 1)",
            borderDash: [6, 3],
            borderWidth: 1,
            pointRadius: 6,
            pointBackgroundColor: (context) => {
                const value = context.raw
                const index = context.index
                const gasEuros = gas_detailed_real_consumption_euros[index] == undefined ? 0 : gas_detailed_real_consumption_euros[index]
                if (value < (detailed_real_consumption_euros[index] + gasEuros)) {
                  return 'rgba(218, 58, 0, 1)'; // red
                } else {
                  return 'rgba(70, 131, 0, 1)'; // green
                }
              },
            cubicInterpolationMode: 'monotone'
        },
        { data: [], type: "bar", backgroundColor: "rgba(16, 107, 207, 0.8)", borderColor: "black", borderRadius: 10 },
        { data: [], type: "bar", backgroundColor: "rgba(16, 107, 207, 0.8)", borderColor: "black", borderRadius: 10 },
        // { data: [], type: "bar", backgroundColor: "rgba(16, 107, 207, 0.8)", borderColor: "black", borderRadius: 10, hidden: true, },
    ]
}
let chartConfig = {
    "type": "bar",
    "data": data,
    "options": {
        "scales": {
            y: {
                stacked: true,
                beginAtZero: true,
                grid: {
                    display: true,
                    color: 'rgba(220, 242, 255, 1)'
                },
                ticks: {
                    color: 'rgba(9, 39, 84, 0.5)',
                    font: {
                        family: 'Montserrat',
                        size: 12,
                        style: 'normal',
                        weight: 'normal'
                    },
                },
                border: {
                    color: 'rgba(220, 242, 255, 1)'
                },
                // title: {
                //     display: true,
                //     text: "euros",
                //     color: "rgba(9, 39, 84, 0.5)",
                //     font: { size: 20 },
                //     padding: {top: 10, left: 0, right: 0, bottom: 0},
                //     align: 'end'
                // }
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: 'rgba(9, 39, 84, 1)',
                    font: {
                        family: 'Montserrat',
                        size: 16,
                        style: 'normal',
                        weight: 'normal'
                    },
                    callback: function(value) {
                        // Divise les labels en deux lignes
                        return this.getLabelForValue(value).split('\n');
                    }
                },
                border: {
                    color: 'rgba(220, 242, 255, 1)'
                },
                stacked: true,
            }
        },
        "responsive": true,
        "plugins": {
            "legend": { "display": false },
            "tooltip": { enabled: false }
        },
        // "onClick": (event, elements) => { // clic sur une bar
        //     if (elements.length > 0) {
        //         const idx = elements[0].index;
        //         const label = data.labels[idx];
        //         const value = data.datasets[1].data[idx];
        //         console.log('Label: ' + label + ', Value: ' + value);
        //         for (var i = 0; i < data.datasets[1].borderWidth.length; i++) {
        //             if (i == idx) {
        //                 data.datasets[1].borderWidth[i] = 4
        //                 data.datasets[1].backgroundColor[i] = barBackgroundColor.value
        //             } else {
        //                 data.datasets[1].borderWidth[i] = 0
        //                 data.datasets[1].backgroundColor[i] = computeBarColor(data.datasets[1].data[i], data.datasets[0].data[i])
        //             }
        //         }
        //         chart.update()
        //         detailPrice.value = value
        //         detailEnergy.value = data.datasets[2].data[idx]
        //     }
        // }
    }
}

const getActionsList = async() => {
    let resp = await API.getActionsList()
    if (resp.status == 200) {
        allActions = resp.data
        isLoadingActions.value = false
    }
}

onMounted(() => {
    chart = new Chart(document.getElementById("energyChart"), chartConfig)

    getConso()
    getActionsList()
    alertsStore.sendTracking(route.path)

    userStore.trackEvent('view_conso', {'page': route.path, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
})
</script>

<style scoped>
#conso_bravo {
    background-image: url('/src/assets/img/consommation_bravo.png');
    background-size: 370px;
    background-repeat: no-repeat;
    background-position: top 0px right -30px;
}

.my-container {
    max-width: 600px;
    min-width: 360px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
}

.prm-select-card:hover {
    background-color: #EEE;
}

.my-image {
    margin-left: 4px;
}

.border-blue{
    border: solid 2px var(--winter-blue-9);
}

.my-dot {
    border-radius: 50%;
    height: 14px;
    width: 14px;
    
    &.inf {
        background-color: var(--winter-green-1);
    }
    &.sup {
        background-color: var(--winter-red-1);
    }
}

.my-pill {
    border-radius: 10px;
    height: 14px;
    width: 50px;
    
    &.elec {
        background-color: var(--winter-blue-7);
    }
    &.gas {
        background-color: var(--winter-orange-2);
    }
}
</style>
