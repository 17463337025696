import { defineStore } from 'pinia';
import alertsDescription from '@/alertsDescription.js';
import { useUserStore } from '@/stores/user.js';

export const useAlertsStore = defineStore('alerts', {
    state: () => ({
        alertStack: new Set()
    }),
    actions: {
        removeAlert(id) {
            this.alertStack.delete(alertsDescription[id])
        },
        addAlert(id, accountId) {
            if (localStorage.getItem(`${id}-${accountId}`) == null) {
                this.alertStack.add(alertsDescription[id])
            }
        },
        sendTracking(path) {
            const userStore = useUserStore()
            this.alertStack.forEach((a) => {
                switch(a.id) {
                    case 'checkEmailGrdf':
                        userStore.trackEvent('view_grdf_alert', {'page': path, 'grdf_alert_status ': 'waiting_for_email'})
                        break;
                    case 'grantingGrdf':
                        userStore.trackEvent('view_grdf_alert', {'page': path, 'grdf_alert_status ': 'give_access_data'})
                        break;
                    case 'grantingGrdfTrue':
                        userStore.trackEvent('view_grdf_alert', {'page': path, 'grdf_alert_status ': 'given_access'})
                        break;
                    case 'homeysError':
                        userStore.trackEvent('view_grdf_alert', {'page': path, 'grdf_alert_status ': 'error'})
                        break;
                    default:
                        console.log("no event tracked")
                }
            })
        }
    }
})
