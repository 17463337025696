<template>
    <div class="mt-5 prm-found-card bg-green-3" style="text-align: start;">
        <div class="flexrow sub-card" style="align-items: start;">
            <div class="text-card">
                <div class="font-size-18 has-text-weight-bold title-part text-green-1 line-height-1-2">
                    <span v-if="!hasCustomPrmText">Super, on a trouvé votre compteur&nbsp;!</span>
                    <span v-else>Merci, votre numéro de compteur est correct !</span>
                </div>
            </div>
            <img src="/src/assets/img/prm-found.svg"/>
        </div>
        <div>
            <div class="flexcol font-size-14 has-text-primary text-part line-height-1-2 mb-4">
                <div class="has-text-weight-bold mb-4">
                    <span>
                        En cochant cette case, vous transmettez des données qui permettent de comprendre vos habitudes de consommation et
                    </span>
                    <span v-if="origin == 'thermostat-connecte' || process == 'thermostat-connecte'">d’identifier les économies que vous pourrez réaliser avec des thermostats connectés.</span>
                    <span v-else>de réduire votre facture d’électricité.</span>
                </div>
                <span>
                    Je déclare sur l’honneur être titulaire du compteur électrique ou être mandaté par celui-ci. 
                    J’autorise Watt Watchers à recueillir mon historique de consommation d’électricité sur 3 ans 
                    (demi-heure, journée et puissance maximum quotidienne), 
                    ainsi qu’à analyser mes consommations tant que j’ai un compte.
                </span>
            </div>

            <!-- Checkbox CONSENT -->
            <label class="flexrow font-size-14 has-text-primary line-height-1-1" style="align-items: center;">
                <input
                    class="checkbox mr-2"
                    type="checkbox"
                    @input="(event) => emit('set-consent', event.target.checked)"
                    style="height: 18px; width: 18px; min-width: 18px; accent-color: var(--winter-green-3);"
                >
                <div class="font-size-12 has-text-weight-semibold" style="display: inline;">
                    J'ai lu et j'accepte. *
                </div>
            </label>
            <p v-if="errors.consent" class="text-error ml-2 font-size-12 mt-1 line-height-1-1">{{ errors.consent }}</p>

            <!-- Checkbox PARTNER CONSENT -->
            <div v-if="origin != null && hasPartnerCheckbox" class="mt-2">
                <label class="flexrow font-size-14 has-text-primary line-height-1-1" style="align-items: center;">
                    <input
                        class="checkbox mr-2"
                        type="checkbox"
                        @input="(event) => emit('set-partner-consent', event.target.checked)"
                        style="height: 18px; width: 18px; min-width: 18px; accent-color: var(--winter-green-3);"
                    >
                    <div class="font-size-12 has-text-weight-semibold" style="display: inline;">
                        <span>J'accepte que mes données de consommation soient partagées avec notre partenaire </span>
                        <a
                            class="is-clickable text-blue-2 has-text-weight-semibold"
                            :href="getWeb"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {{getName}}
                        </a>
                        <span>.</span>
                    </div>
                </label>
            </div>
        </div>
    </div>
    <div class="prm-found-card" style="">
        <!-- Checkbox CGU -->
        <label class="flexrow font-size-14 has-text-primary">
            <input
                class="checkbox mr-2"
                type="checkbox"
                @input="(event) => emit('set-cgu', event.target.checked)"
                style="height: 18px; width: 18px; min-width: 18px; accent-color: var(--winter-green-3);"
            >
            <div class="font-size-12 has-text-weight-semibold line-height-1-1" style="display: inline; text-align: start;">
                <span>J’accepte les </span>
                <a @click.prevent="emit('open-cgu')" class="is-underlined is-clickable text-blue-2 has-text-weight-semibold">conditions générales d'utilisation</a>
                <span> et la </span>
                <a
                    class="is-underlined is-clickable text-blue-2 has-text-weight-semibold"
                    href="https://www.winter-energies.fr/politique-de-confidentialite"
                    target="_blank"
                    rel="noopener noreferrer"
                >politique de confidentialité.</a>
                <span> *</span>
            </div>
        </label>
        <p v-if="errors.cgu" class="text-error ml-2 font-size-12 mt-1 line-height-1-1">{{ errors.cgu }}</p>
    </div>
</template>

<script setup>
import { partersReadableNames } from '@/utils.js';
import { computed } from 'vue';

const props = defineProps({
    hasCustomPrmText: Boolean,
    errors: Object,
    origin: String,
    process: String,
    hasPartnerCheckbox: Boolean,
})

const emit = defineEmits(["set-cgu", "set-consent", "open-cgu", "set-partner-consent"])

const getWeb = computed(() => {
    let web = undefined
    if(Object.keys(partersReadableNames).includes(props.origin)) {
        web = partersReadableNames[props.origin]['web']
    }
    return web
})

const getName = computed(() => {
    let name = props.origin
    if(Object.keys(partersReadableNames).includes(props.origin)) {
        name = partersReadableNames[props.origin]['human']
    }
    return name
})

</script>

<style scoped>
.prm-found-card {
    border-radius: 10px;
    padding: 15px;
    width: 100%;
}
.prm-found-card .sub-card {
    margin: 0 auto 10px auto;
    max-width: 310px;
}
.prm-found-card .sub-card .text-card {
    max-width: 220px;
    min-width: 220px;
}
.prm-found-card .sub-card .text-card .title-part {
    margin-bottom: 5px;
}
.prm-found-card .sub-card img {
    margin-top: -25px;
    z-index: 1;
}
</style>