////
// There is 3 types : info, success, warning

const alertsDescription = {
    'fewData': {
        id: 'fewData',
        type: 'warning',
        mainText: [
            {
                class: '', 
                text: 'Votre expérience sera incomplète car nous avons récupéré peu de données de chauffage pour votre logement.'
            }
        ],
        subText: [],
        more: true,
        moreLink: '',
        isClosable: false,
        action: false,
        actionText: ''
    },
    'noHeat': {
        id: 'noHeat',
        type: 'warning',
        mainText: [
            {
                class: '',
                text: 'Votre expérience sera incomplète car nous n’avons pas détecté de chauffage électrique pour votre logement.'
            }
        ],
        subText: [
            // {
            //     class: '',
            //     text: 'Vous pouvez nous contacter à '
            // }, {
            //     class: 'contact',
            //     text: 'contact@wattwatchers.fr '
            // }, {
            //     class: '',
            //     text: 'pour que nous vous aidions.'
            // }
        ],
        more: true,
        moreLink: '',
        isClosable: false,
        action: false,
        actionText: ''
    },
    'noHeatOrGaz': {
        id: 'noHeatOrGaz',
        type: 'warning',
        mainText: [
            {
                class: '',
                text: 'Votre expérience sera incomplète car nous n’avons pas détecté de chauffage électrique ou gaz pour votre logement.'
            }
        ],
        subText: [],
        more: true,
        moreLink: '',
        isClosable: false,
        action: false,
        actionText: ''
    },
    'checkEmailGrdf': {
        id: 'checkEmailGrdf',
        type: 'success',
        mainText: [
            {
                class: '',
                text: 'Bravo ! Vous recevrez un email de GRDF, votre distributeur de gaz, d’ici 15 minutes.'
            }
        ],
        subText: [
            {
                class: '',
                text: 'Vous pourrez alors donner accès à vos données de gaz à notre partenaire '
            },
            {
                class: 'has-text-weight-bold',
                text: 'homeys'
            },
            {
                class: '',
                text: '.'
            }
        ],
        more: false,
        moreLink: '',
        isClosable: true,
        action: false,
        actionText: ''
    },
    'grantingGrdf': {
        id: 'grantingGrdf',
        type: 'info',
        mainText: [
            {
                class: '',
                text: 'Vous avez reçu un email de GRDF pour donner accès à vos données de gaz à notre partenaire '
            },
            {
                class: 'has-text-primary',
                text: 'homeys'
            },
            {
                class: '',
                text: '.'
            },
        ],
        subText: [],
        more: true,
        moreLink: '',
        isClosable: false,
        action: true,
        actionText: 'J’ai donné accès à mes données'
    },
    'grantingGrdfTrue': {
        id: 'grantingGrdfTrue',
        type: 'info',
        mainText: [
            {
                class: '',
                text: 'Bravo ! Vos données de gaz seront disponibles dans quelques minutes.'
            }
        ],
        subText: [],
        more: false,
        moreLink: '',
        isClosable: true,
        action: false,
        actionText: ''
    },
    'homeysError': {
        id: 'homeysError',
        type: 'warning',
        mainText: [
            {
                class: '',
                text: 'Oh non, il y a eu une erreur au moment de contacter GRDF.'
            }
        ],
        subText: [
            {
                class: '',
                text: 'Vous pouvez nous contacter à '
            }, {
                class: 'contact',
                text: 'contact@wattwatchers.fr '
            }, {
                class: '',
                text: 'pour que nous vous aidions.'
            }
        ],
        more: false,
        moreLink: '',
        isClosable: true,
        action: false,
        actionText: ''
    },
    'homeysProcessAborted': {
        id: 'homeysProcessAborted',
        type: 'info',
        mainText: [
            {
                class: '',
                text: 'Vous n’avez pas finalisé l’ajout de votre compteur de gaz.'
            }
        ],
        subText: [
            {
                class: '',
                text: 'Nos recommendations sont moins précises car nous n’avons pas accès à votre consommation de gaz.'
            }
        ],
        more: false,
        moreLink: '',
        isClosable: false,
        action: true,
        actionText: 'Ajouter mon compteur de gaz'
    }
}

export default alertsDescription
