<template>
    <div class="my-container">
        <TopCard origin="regular"/>
        <div class="content-container" style="justify-content: space-between;">
            <div class="font-size-20 has-text-primary has-text-weight-bold has-text-centered mt-6 line-height-1-2">
                Veuillez patienter, nous vous connectons à Watt Watchers.
            </div>
            <div v-if="error || duplicateError" class="mt-6">
                <p v-if="error" class="has-text-centered text-error">{{ error }}</p>
                <p v-if="duplicateError" class="flexcol has-text-centered text-error">
                    <span>Oups, il y a une erreur.</span>
                    <span>Ce PCE existe déjà chez nous.</span>
                    <span>Contactez <a href="mailto:contact@wattwatchers.fr">contact@wattwatchers.fr</a> pour que nous vous aidions.</span>
                </p>
                <!-- Button "Retour" -->
                <RouterLink
                    class="is-clickable has-text-centered has-text-primary font-size-14 has-text-weight-bold mt-5 mb-2"
                    style="text-decoration: underline; display: block; width: 100%;"
                    :to="{name: 'HomeRouter'}"
                >
                    Accueil
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import API from '@/services/api';
import { useUserStore } from '@/stores/user';
import { useRouter, useRoute } from 'vue-router';
import TopCard from '@/components/auth/TopCard.vue';

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

const error = ref(null)
const duplicateError = ref(false)

onBeforeMount(async() => {
    if (Object.keys(route.query).length > 0) {
        console.log("Params in query!", route.query)
        
        let payload = {
            status: route.query.status || null,
            error_code: route.query.error || null,
            errpr_message: route.query.message || null,
            pce: route.query.pce || null,
            id_source: route.query._id_source || null,
            id_consent: route.query._id_consent || null,
        }
        
        try {
            console.log("API CALLED")
            let resp = await API.endingGasSignup(userStore.account.id, payload)
            if (resp.status == 200) {
                console.log("GAS SIGN UP DONE !")
                router.push({name: 'HomeRouter'})
            }
        } catch (e) {
            console.log("Une erreur est survenue", e.response.data)
            if (JSON.stringify(e.response.data).indexOf('core_account_gazpar_pce_key') >= 0) {
                duplicateError.value = true
            } else {
                error.value = e.response.data
            }
        }
    }
})
</script>

<style scoped>
.my-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0 !important;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 60px 20px;
}

.content-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    border-radius: 25px 25px 0 0;
}
</style>

