<template>
    <div class="flexcol radius-10 border-blue-4 bg-white" style="border-width: 2px; border-style: solid;">
        <div class="flexrow bg-blue-4" style="height: 36px; padding: 8px; gap: 6px;">
            <img src="/src/assets/img/my_home_title.png" style="max-width: 20px;"/>
            <span class="font-size-12 has-text-weight-bold text-blue-2" style="align-content: center;">Mon foyer</span>
        </div>
        <div class="flexrow" style="height: 36px;">
            <div :class="`flexrow cell border-blue-4 bb ${ survey.type && !['house', 'terraced_house', 'detached_house'].includes(survey.type) ? 'br' : ''}`">
                <img src="/src/assets/img/my_home_type.svg"/>
                <span v-if="survey.type" class="font-size-12 has-text-weight-medium has-text-primary" style="align-self: center;">
                    {{ mapTypeText[survey.type] }}
                </span>
            </div>
            <div v-if="survey.type && !['house', 'terraced_house', 'detached_house'].includes(survey.type)" class="flexrow cell border-blue-4 bl bb">
                <img src="/src/assets/img/my_home_nb_people.svg"/>
                <span v-if="survey.nb_people" class="font-size-12 has-text-weight-medium has-text-primary" style="align-self: center;">
                    {{ survey.nb_people }} personnes
                </span>
            </div>
        </div>
        <div class="flexrow" style="height: 36px;">
            <div class="flexrow cell border-blue-4 br bt">
                <img src="/src/assets/img/my_home_surface.svg"/>
                <span v-if="survey.surface" class="font-size-12 has-text-weight-medium has-text-primary" style="align-self: center;">
                    {{ survey.surface }}m²
                </span>
            </div>
            <div v-if="survey.type && !['house', 'terraced_house', 'detached_house'].includes(survey.type)" class="flexrow cell border-blue-4 bl bt">
                <img src="/src/assets/img/my_home_floor.svg"/>
                <span v-if="survey.floor" class="font-size-12 has-text-weight-medium has-text-primary line-height-1-1" style="align-self: center;">
                    {{ mapFloorText[survey.floor] }}
                </span>
            </div>
            <div v-else class="flexrow cell border-blue-4 bl bt">
                <img src="/src/assets/img/my_home_nb_people.svg"/>
                <span v-if="survey.nb_people" class="font-size-12 has-text-weight-medium has-text-primary" style="align-self: center;">
                    {{ survey.nb_people }} personnes
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>

const props = defineProps({
    survey: Object
})

const mapTypeText = {
    'apartment': 'Appartement',
    'office': 'Bureau',
    'studio': 'Studio',
    'house': 'Maison',
    'terraced_house': 'Maison mitoyenne',
    'detached_house': 'Maison isolée'
}

const mapFloorText = {
    'last_floor': "Dernier étage",
    'last_floor_under_attic': "Dernier étage, sous combles non aménagés",
    'last_floor_under_roof': "Dernier étage, sous toit",
    'middle_floor': "Étage intermédiaire",
    'ground_floor': "Rez-de-chaussée"
}

</script>

<style scoped>
.cell {
    flex: 1;
    padding: 10px;
    gap: 6px; 
}

.cell.br {
    border-right-width: 1px;
    border-right-style: solid;
}
.cell.bb {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}
.cell.bl {
    border-left-width: 1px;
    border-left-style: solid;
}
.cell.bt {
    border-top-width: 1px;
    border-top-style: solid;
}
</style>