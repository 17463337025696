<template>
    <div class="flexcol my-4" style="gap: 6px;">
        <Alert
            v-for="a in alertsStore.alertStack"
            :key="a.id"
            :alert="a"
            @close="handleClose(a.id)"
            @action="handleAction(a.id, 'page')"
            @more="openModal(a.id)"
        />
    </div>
    <ModalInfoAboutFewData :active="showModalInfoAboutFewData" @toggle-info="showModalInfoAboutFewData = !showModalInfoAboutFewData"/>
    <ModalInfoAboutNoHeat :active="showModalInfoAboutNoHeat" @toggle-info="showModalInfoAboutNoHeat = !showModalInfoAboutNoHeat"/>
    <ModalInfoAboutGrantingGrdf
        :active="showModalInfoAboutGrantingGrdf"
        @toggle-info="showModalInfoAboutGrantingGrdf = !showModalInfoAboutGrantingGrdf"
        @granting-grdf="handleAction('grantingGrdf', 'modal')"
        @no-granting-grdf="handleNoGrantingGrdf()"/>
</template>

<script setup>
import { ref } from "vue";
import API from "@/services/api";
import Alert from "@/components/Alert.vue";
import { useAlertsStore } from "@/stores/alert";
import { useUserStore } from "@/stores/user";
import { useRoute, useRouter } from "vue-router";
import ModalInfoAboutFewData from "@/components/modals/ModalInfoAboutFewData.vue";
import ModalInfoAboutNoHeat from "@/components/modals/ModalInfoAboutNoHeat.vue";
import ModalInfoAboutGrantingGrdf from "@/components/modals/ModalInfoAboutGrantingGrdf.vue";

const userStore = useUserStore()
const alertsStore = useAlertsStore()
const route = useRoute()
const router = useRouter()
const showModalInfoAboutFewData = ref(false)
const showModalInfoAboutNoHeat = ref(false)
const showModalInfoAboutGrantingGrdf = ref(false)

const props = defineProps({
    alert: Object,
})

const openModal = (alertId) => {
    if(alertId == 'fewData') {
        showModalInfoAboutFewData.value = true
        userStore.trackEvent('click_tooltip', {'page': route.path, 'tooltip_name': 'not_enough_data'})
    }
    else if(alertId == 'noHeat') {
        showModalInfoAboutNoHeat.value = true
        userStore.trackEvent('click_tooltip', {'page': route.path, 'tooltip_name': 'no_heat'})
    }
    else if(alertId == 'grantingGrdf') {
        showModalInfoAboutGrantingGrdf.value = true
        userStore.trackEvent('click_tooltip', {'page': route.path, 'tooltip_name': 'given_access'})
    }
}

const handleClose = (alertId) => {
    alertsStore.removeAlert(alertId)
    localStorage.setItem(`${alertId}-${userStore.account.id}`, 'closed')
}

const handleAction = async(alertId, fromWhere) => {
    console.log("Handle action", alertId)
    if (alertId == 'grantingGrdf') {
        try {
            let resp = await API.grantingGrdf(
                userStore.account.id,
                {
                    granting_grdf: true
                }
            )
            if (resp.status == 200) {
                await userStore.update_profile()
                showModalInfoAboutGrantingGrdf.value = false
                userStore.trackEvent('click_already_given_access', {'page': route.path, 'grdf_alert_status': 'give_access_data', 'from': fromWhere})
            } else {
                console.log("handleAction grantingGrdf error", resp)
            }
        } catch(e) {
            console.log("handleAction grantingGrdf error", e)
        }
    }
    else if (alertId == 'homeysProcessAborted') {
        router.push({ name: "WaitingScreenGas" })
    }
}

const handleNoGrantingGrdf = () => {
    showModalInfoAboutGrantingGrdf.value = false
    userStore.trackEvent('click_not_given_access', {'page': route.path, 'grdf_alert_status': 'give_access_data'})
}

</script>

<style scoped>

</style>